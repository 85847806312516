import React from "react";
import Container from "react-bootstrap/Container";
import Accordion from 'react-bootstrap/Accordion';
import alunosTurma from './alunosTurmaData';
import { AccordionItem } from "react-bootstrap";
import Profile from "../../components/team/Profile";

function Egressos() {

    // Default key for accordion
    //const defaultKey = 0 // comentando para que o Accordion inicie fechado

    //implementando retorno de alunos da turma para view
    const alunosTurmas = alunosTurma.map((turma, index) => {

        const profile = turma.info.map((c, subindex) => {

            return (
                <div className="our-team" key={subindex}>
                    <Profile name={c.name} avatar={c.img_profile} github={c.linkGit} />
                </div>
            )

        });


        //retornando cada turma com respectivo Accordion
        return (
            <AccordionItem eventKey={index} key={index}>
                <Accordion.Button style={{ backgroundColor: "#3E79BC", color: 'white', fontSize: '16pt', fontStyle: 'bold' }}>
                    {turma.turma} ({turma.info.length})
                </Accordion.Button>
                <Accordion.Body>
                    <div className="row text-center">
                        <div className="wow fadeInUp">
                            {profile}
                        </div>
                    </div>
                </Accordion.Body>
            </AccordionItem>
        );


    })

    return (

        <Container>

            <div className="row g-3 justify-content-center mb-5" >

                <div className="col-md-10">
                    <h2 style={{ marginTop: '50px', marginBottom: "20px" }}> Conheça nossos Egressos </h2>

                    <Accordion
                        //defaultActiveKey={defaultKey} comentando para que o Accordion inicie fechado
                        alwaysOpen
                        style={{
                            "--bs-accordion-border-color": "#c1c1c1",
                            "--bs-accordion-active-color": "#000000",
                            "--bs-accordion-active-bg": "#F5F5F5",
                            "--bs-accordion-btn-focus-border-color": "#F5F5F5",
                            "--bs-accordion-btn-focus-box-shadow": "-1 0 0 0.25rem rgba(24, 29, 38, 0.41)",
                            //"font-color": "#000000"
                        }}>


                        {alunosTurmas}

                    </Accordion>

                </div>
            </div>


        </Container >
    )
}

export default Egressos;