const contatoData = [
	{
		contatos: [
			{
				funcao: "Coordenação",
				nome: "Alexandre Passito",
				email: "coord.webacademy@icomp.ufam.edu.br"
			}
		]
	}
]

export default contatoData