import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Home from './pages/home/Home';
import Curso from './pages/curso/Curso';
import ProcessoSeletivo from './pages/processoSeletivo/ProcessoSeletivo';
//import Cronograma from './pages/cronograma/Cronograma';
import Contato from './pages/contato/Contato';
import Egressos from './pages/egressos/Egressos';
import Portfolio from './pages/portfolio/Portfolio';
import Docentes from './pages/docentes/Docentes';



function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/curso' element={<Curso />} />
          <Route path='/processo_seletivo' element={<ProcessoSeletivo />} />
          {/* <Route path='/cronograma' element={<Cronograma />} /> */}
          <Route path='/egressos' element={<Egressos />} />
          <Route path='/portfolio' element={<Portfolio />} />
          <Route path='/docentes' element={<Docentes />} />
          <Route path='/contato' element={<Contato />} />
        </Routes>
        <Footer />
      </BrowserRouter>

    </div>
  );
}

export default App;
