import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import './Header.css';


function Header() {
	return (
		<Navbar collapseOnSelect expand="lg" className="custom-nav" >

			<Container>

				<Navbar.Brand href="/">
					<img
						alt="web academy logo globo com ferramentas web"
						src="assets/img/logo_letra_branca.png"
						style={{ maxHeight: "10vh" }}
						className="img-fluid d-inline-block align-top"
					/>{' '}
				</Navbar.Brand>

				<Navbar.Toggle aria-controls="basic-navbar-nav" />

				<Navbar.Collapse id="basic-navbar-nav" >

					<Nav className="me-auto">
						<LinkContainer to="/">
							<Nav.Link aria-label='home'>Início</Nav.Link>
						</LinkContainer>
						<LinkContainer to="/curso">
							<Nav.Link aria-label='curso'>Curso</Nav.Link>
						</LinkContainer>
						<LinkContainer aria-label='docentes' to="/docentes">
							<Nav.Link>Docentes</Nav.Link>
						</LinkContainer>
						<LinkContainer to="/processo_seletivo">
							<Nav.Link aria-label='processo seletivo'>Editais</Nav.Link>
						</LinkContainer>
						{/* <LinkContainer to="/cronograma">
							<Nav.Link aria-label='cronograma'>Cronograma</Nav.Link>
						</LinkContainer> */}
						<LinkContainer aria-label='ex-alunos' to="/egressos">
							<Nav.Link>Egressos</Nav.Link>
						</LinkContainer>
						<LinkContainer aria-label='portfolio' to="/portfolio">
							<Nav.Link>Portfólio</Nav.Link>
						</LinkContainer>
						<LinkContainer aria-label='contato' to="/contato">
							<Nav.Link>Contato</Nav.Link>
						</LinkContainer>

					</Nav>

					<a aria-label='instagram' target="_blank" rel="noopener noreferrer" href="https://instagram.com/webacademyufam?igshid=ZDdkNTZiNTM=">
						<FontAwesomeIcon icon={faInstagram} style={{ color: 'white', fontSize: '35px' }} />
					</a>


				</Navbar.Collapse>


			</Container>
		</Navbar>



	);
}

export default Header;