import 'bootstrap/dist/css/bootstrap.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';

function renderDevelopers(developers) {
    return developers.map((dev, index) => (
        <img
            key={index}
            src={dev.img_profile}
            className="rounded-circle mr-1"
            alt={dev.name}
            width="28"
            height="28"
            data-toggle="tooltip"
            title={dev.name}
        />
    ));
}

function CardView({ title, description, developers, status, logotipo, gitgithub }) {
    const developerImages = renderDevelopers(developers);

    return (
        <div className="card card-custom">
            <img
                className="card-img-top"
                src={logotipo}
                alt="Unsplash"
                height={180}
                width={250}
            />

            <div className="card-header px-4 pt-4">
                <div className="card-actions float-right">
                    <a href={gitgithub} rel="noopener noreferrer" target="_blank" style={{ color: '#010409' }} data-toggle="tooltip" title={gitgithub}>
                        <FontAwesomeIcon icon={faGithub} />
                    </a>
                </div>
                <h5 className="card-title mb-0">{title}</h5>
                <div className={status === 'Finalizado' ? 'badge bg-success' : 'badge bg-warning'}>
                    {status}
                </div>
            </div>

            <div className="card-body px-4 pt-2">
                <p>{description}</p>
                {developerImages}
            </div>
        </div>
    );
}

export default CardView;
