import React from "react";
import Container from "react-bootstrap/Container"

import contatoData from "./contatoData";

function Contato() {

	const content = contatoData.map(c => {

		const contatoCols = c.contatos.map((e, subindex) => {
			return (
				<div className="col-md-6 mt-5 text-start" key={subindex}>

					<h3>{e.funcao}</h3>
					<hr />

					<p><strong>Nome</strong></p>
					<p className="text-wrap text-break">{e.nome}</p>
					<p><strong>E-mail</strong></p>
					<p className="text-wrap text-break">{e.email}</p>

				</div>
			)
		})

		return (

			contatoCols

		)
	})

	return (
		<Container className="mt-5">
			<div className="row justify-content-center text-center">
				<h3>Contato</h3>

				{content}
			</div>



		</Container>
	)
}

export default Contato