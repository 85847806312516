import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './CardDocente.css';
import { Link } from 'react-router-dom';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

function CardDocente(props) {

    return (
        <div className="contact-list">
            <div className="contact-win">
                <div className="contact-img">
                    <img src={props.image} alt="" style={{ width: "80px", height: "80px" }} />
                </div>

                <div className='contact-ctn'>
                    <div className='contact-flex-container'>
                        <div className='contact-ad-hd'>
                            <h2>{props.nome}</h2>
                            <p className='ctn-ads'>{props.titulacao}</p>
                        </div>
                        <div className="conct-sc-ic">
                            <Link className='btn waves-effect' to={props.linkLinkedin} target='_blank'>
                                <FontAwesomeIcon icon={faLinkedin} />
                            </Link>

                            <Link className='btn waves-effect' to={props.linkGit} target='_blank'>
                                <FontAwesomeIcon icon={faGithub} />
                            </Link>

                        </div>
                    </div>
                    <p style={{ textAlign: "justify" }}>{props.bio}</p>
                </div>
            </div>



        </div>
    )

}


export default CardDocente