const slideImages = [
    {
        url: 'assets/img/divulgacao/pyramid.png',
        caption: 'Produto Desenvolvido no Hands-on Turma 1🥳'
    },
    {
        url: 'assets/img/divulgacao/t3_final.jpg',
        caption: 'Parabéns! Novos Devs FullStack Turma 3 🥳'
    },
    {
        url: 'assets/img/divulgacao/turma4.jpg',
        caption: 'Início das aulas da 4ª Turma  WebAcademy! 🚀'
    },


    // {
    //     url: 'assets/img/divulgacao/edital002_2024.jpeg',
    //     caption: 'Aberta novas vagas 📣'
    // },

    {
        url: 'assets/img/divulgacao/turma2_cc.jpg',
        caption: 'Novos Devs FullStack da 2ª Turma! 🥳'
    },

    // {
    //     url: 'assets/img/divulgacao/turma3.jpg',
    //     caption: 'Olá Turma 3! 🚀'
    // },

    // {
    //     url: 'assets/img/divulgacao/kickoff1turma.png',
    //     caption: 'KickOff da 1ª Turma do Projeto WebAcademy'
    // },
    // {
    //     url: 'assets/img/divulgacao/convite2kickoff.png',
    //     caption: 'Convite para Cerimônia de Lançamento da 2ª Turma do Web Academy'
    // },
    // {
    //     url: 'assets/img/divulgacao/kickoff2turma.png',
    //     caption: 'KickOff da 2ª Turma do Projeto WebAcademy'
    // },
    {
        url: 'assets/img/divulgacao/turma1.jpg',
        caption: 'Entrega de certificados para 1ª Turma do Projeto WebAcademy'
    },
    {
        url: 'assets/img/divulgacao/inauguracao.png',
        caption: 'Em parceria com Motorola, UFAM inaugura laboratórios de P&D'
    },

];

export default slideImages