

const ToggleContent = ({isVisible}) => {

  return (
    <>

    {isVisible && (

        <section className="py-5" style={{ backgroundColor: '#ecf0f1' }}>
          <div className="container px-5">
            <div className="row gx-5">
              <div className="col">
                <h2 className="fw-bolder fs-5 mb-4">Edital 001/2024</h2>

                <div className="mb-2">
                  {/* <div className="small text-muted">May 12, 2023</div> */}
                  <a target="_blank" rel="noopener noreferrer" href="files/edital_001_2024/edital_001_2024.pdf" >Edital 001/2024 - Seleção de Discentes</a>
                </div>

                <div className="mb-2">
                  {/* <div className="small text-muted">May 5, 2023</div> */}
                  <a target="_blank" rel="noopener noreferrer" href="files/edital_001_2024/homologacao_inscricoes_edital_001_2024.pdf" >Resultado da Homologação das Inscrições</a>
                </div>

                <div className="mb-2">
                  {/* <div className="small text-muted">Apr 21, 2023</div> */}
                  <a target="_blank" rel="noopener noreferrer" href="files/edital_001_2024/Edital 001_2024_Resultado_Final_Preliminar.pdf" >Resultado Final Preliminar da Homologação das Inscrições</a>
                </div>

                <div className="mb-4">
                  {/* <div className="small text-muted">Apr 21, 2023</div> */}
                  <a target="_blank" rel="noopener noreferrer" href="files/edital_001_2024/Edital_001_2024_-_Resultado_Final.pdf" >Resultado Final</a>
                </div>


                <h2 className="fw-bolder fs-5 mb-4">Edital 002/2023</h2>

                <div className="mb-2">
                  {/* <div className="small text-muted">May 12, 2023</div> */}
                  <a target="_blank" rel="noopener noreferrer" href="files/edital_002_2023/edital_002_2023.pdf" >Edital 002/2023 - Seleção de Discentes</a>
                </div>

                <div className="mb-2">
                  {/* <div className="small text-muted">May 5, 2023</div> */}
                  <a target="_blank" rel="noopener noreferrer" href="files/edital_002_2023/errata_edital_002_2023.pdf" >ERRATA 01 - Edital 002/2023</a>
                </div>

                <div className="mb-2">
                  {/* <div className="small text-muted">Apr 21, 2023</div> */}
                  <a target="_blank" rel="noopener noreferrer" href="files/edital_002_2023/errata2_edital_002_2023.pdf" >ERRATA 02 - Edital 002/2023</a>
                </div>

                <div className="mb-2">
                  {/* <div className="small text-muted">Apr 21, 2023</div> */}
                  <a target="_blank" rel="noopener noreferrer" href="files/edital_002_2023/homologacao_inscricoes_edital_002_2023.pdf" >Resultado da Homologação das Inscrições</a>
                </div>

                <div className="mb-2">
                  {/* <div className="small text-muted">Apr 21, 2023</div> */}
                  <a target="_blank" rel="noopener noreferrer" href="files/edital_002_2023/errata_homologacao_inscricoes_edital_002_2023.pdf" >Errata - Resultado da Homologação das Inscrições</a>
                </div>

                <div className="mb-2">
                  {/* <div className="small text-muted">Apr 21, 2023</div> */}
                  <a target="_blank" rel="noopener noreferrer" href="files/edital_002_2023/resultado_edital_002_2023.pdf" >Resultado Final Preliminar</a>
                </div>


                <div className="mb-4">
                  {/* <div className="small text-muted">Apr 21, 2023</div> */}
                  <a target="_blank" rel="noopener noreferrer" href="files/edital_002_2023/resultado_final_edital_002_2023.pdf" >Resultado Final</a>
                </div>

                <h2 className="fw-bolder fs-5 mb-4">Edital 001/2023</h2>

                <div className="mb-2">
                  {/* <div className="small text-muted">May 12, 2023</div> */}
                  <a target="_blank" rel="noopener noreferrer" href="files/edital_001_2023/edital_001_2023.pdf" >Edital 001/2023 - Seleção de Discentes</a>
                </div>
              </div>
            </div>
          </div>
        </section>

      
    )}
  </>
  )

}

export default ToggleContent;