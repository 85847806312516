import React from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import slideImages from './ImagensDB';


const spanStyle = {
    padding: '20px',
    background: 'rgb(239, 239, 239, 0.9)',
    color: '#000000',
    fontSize: '1.5em',
    borderRadius: '10px 10px 0px 0px '
}

const divStyle = {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '455px',
    borderRadius: '10px'
}


const SlideShow = () => {

    return (
        <div className="slide-container my-5">
            <Fade>
                {
                    slideImages.map((slideImage, index) => (
                        <div key={index}>
                            <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
                                <span style={spanStyle}>{slideImage.caption}</span>
                            </div>
                        </div>
                    ))
                }
            </Fade >
        </div >
    )

}

export default SlideShow;

