import './Card.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



function Card(props) {

    return (

        <div className="card">
            <div className="card-body">
                <div className="float-right">
                    <FontAwesomeIcon icon={props.icon} style={{ color: '#3E79BC', fontSize: '25px' }} />
                </div>
                <h5 className="font-size-20 mt-0 pt-1">{props.qtd}</h5>
                <p className="text-muted mb-0">{props.descricao}</p>
            </div>
        </div>

    )


}

export default Card;