import { LinkContainer } from 'react-router-bootstrap';
import { Link } from "react-router-dom";
import styled from 'styled-components'

const StyledLink = styled(Link)`
	text-decoration: none;
	color: #3E79BC;
`;


function Footer() {
	return (

		<footer className='text-dark p-3 mx-0 mt-5' style={{ background: "#ecf0f1" }}>
			<div className="row justify-content-center align-itens-center">

				<div className="col-md-4 col-sm-6">
					<h5>Site</h5>

					<ul style={{ listStyleType: "none" }}>
						<li>
							<LinkContainer to="/">
								<StyledLink aria-label='home'>Início</StyledLink>
							</LinkContainer>
						</li>
						<li>
							<LinkContainer to="/curso">
								<StyledLink aria-label='curso'>Curso</StyledLink>
							</LinkContainer>
						</li>
						<li>
							<LinkContainer to="/docentes">
								<StyledLink aria-label='docentes'>Docentes</StyledLink>
							</LinkContainer>
						</li>
						<li>
							<LinkContainer to="/processo_seletivo">
								<StyledLink aria-label='processo seletivo'>Editais</StyledLink>
							</LinkContainer>
						</li>

						<li>
							<LinkContainer to="/portfolio">
								<StyledLink aria-label='portfolio'>Portfólio</StyledLink>
							</LinkContainer>
						</li>
						<li>

							<LinkContainer to="/egressos">
								<StyledLink aria-label='egressos'>Egressos</StyledLink>
							</LinkContainer>
						</li>
						<li>
							<LinkContainer to="/contato">
								<StyledLink aria-label='contato'>Contato</StyledLink>
							</LinkContainer>
						</li>
					</ul>


				</div>

				<div className="col-md-4 col-sm-6">
					<h5>Instituto de Computação</h5>
					<ul style={{ listStyleType: "none" }}>
						<li>Avenida General Rodrigo Octávio Jordão Ramos, 6200 </li>
						<li>UFAM - Setor Norte </li>
						<li>Instituto de Computação </li>
						<li>69080-900 - Manaus - AM </li>
						<li><a href="https://goo.gl/maps/wxEKiYhFQ9wAKzuM8" aria-label='localizacao no mapa icomp' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#3E79BC' }}>Localização no mapa</a></li>

					</ul>
				</div>
			</div>
		</footer>
	)
}

export default Footer