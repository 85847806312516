import './Profile.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';


const Profile = (props) => {

    return (
        <>
            {/* <div className="our-team"> */}
            <div className="single-team">
                <img
                    src={props.avatar}
                    className="img-fluid" alt="" />

                <ul className="social">
                    <li>
                        {
                            props.github !== "#" ?
                                <a href={props.github} rel="noopener noreferrer" target="_blank" className="github" data-toggle="tooltip" title={props.github}>
                                    <FontAwesomeIcon icon={faLinkedin} style={{ color: '#70757a' }} />
                                </a> : <a href={() => false}>...</a>}
                    </li>

                </ul>

                <h3 style={{ color: '#70757a' }}>{props.name}</h3>
                {/* <p>Developer</p> */}

            </div >

        </>


    )

}

export default Profile