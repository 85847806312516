const DocentesData = [
    {
        id: "1",
        nome: "Alexandre Passito",
        titulacao: "Doutor em Informática",
        image: 'assets/img/docentes/passito.jpg',
        //bio: "Coordenador do Projeto WebAcademy e Profº IComp/UFAM. Coordena projetos de capacitação e pesquisa em parceria com a Motorola voltados para áreas de Web FullStack, Redes de Computadores e Cybersecurity.",
        bio: "Professor do Instituto de Computação da UFAM (IComp/UFAM), onde ministra disciplinas para os cursos de Ciência da Computação e Engenharias. Realiza pesquisa nas áreas de Redes de Computadores, Internet e Cybersecurity. Atualmente coordena um subgrupo de pesquisadores e alunos no Projeto SUPER - Educação e Pesquisa, em parceria com a Samsung, e também coordena o Projeto Web Academy - Capacitação e Desenvolvimento em Web Full Stack, em parceria com a Motorola.",
        linkLinkedin: "https://www.linkedin.com/in/alexandre-passito-7522924b/",
        linkGit: ""
    },
    {
        id: "2",
        nome: "Moisés Carvalho",
        image: 'assets/img/docentes/moises.jpeg',
        titulacao: "Doutor em Informática",
        // bio: "Profº IComp/UFAM. Especialista área de Ciência da Computação, com ênfase em Banco de Dados (BD) e Recuperação de Informação (RI)",
        bio: "Possui Graduação em Processamento de Dados pela Universidade Federal do Amazonas (2000), mestrado em Informática pela Universidade Federal do Amazonas (2004) e doutorado em Ciência da Computação pela Universidade Federal de Minas Gerais(2009). Tem experiência na área de Ciência da Computação, com ênfase em Banco de Dados (BD) e Recuperação de Informação (RI)",
        linkLinkedin: "https://www.linkedin.com/in/carvalhomoises/",
        linkGit: ""
    },
    {
        id: "3",
        nome: "David Fernandes",
        image: 'assets/img/docentes/david.jpeg',
        titulacao: "Doutor em Informática",
        // bio: "Profº IComp/UFAM. Especialista em Recuperação de Informação e Informática na Educação, desenvolveu o CodeBench, um sistema juiz online para automatização de correções de exercícios de programação.",
        bio: "Possui Graduação em Processamento de Dados pela Universidade Federal do Amazonas (2001), mestrado em Informática pela Universidade Federal do Amazonas (2004) e doutorado em Ciência da Computação pela Universidade Federal de Minas Gerais (2010). Tem experiência na área Recuperação de Informação e Informática na Educação, tem atuado nos seguintes tópicos de pesquisa: busca em conteúdo estruturado, juizes online, gamificação de sistemas educacionais, desenvolvimento web. Atualmente é professor Adjunto do Instituto de Computação (IComp) da Universidade Federal do Amazonas (UFAM), onde desenvolveu o CodeBench (https://codebench.icomp.ufam.edu.br), que é um sistema juiz online usado para automatizar a correção dos exercícios das disciplinas de programação.",
        linkLinkedin: "https://www.linkedin.com/in/david-fernandes-67aa891a/",
        linkGit: ""
    },
    {
        id: "4",
        nome: "Júlia Luiza",
        image: 'assets/img/docentes/julia.jpeg',
        titulacao: "Especialista Eng. Software",
        bio: "Especialização pela UNICAMP/Extecamp. Graduada em Engenharia de Software (UFAM). Possui curso Técnico de Nível Médio em Informática (IFAM). Possui experiências em desenvolvimento Web, Engenharia de Software e UI/UX.",
        linkLinkedin: "https://www.linkedin.com/in/julialuiza/",
        linkGit: "https://github.com/julialuiza/"
    },
    {
        id: "5",
        nome: "Ana Oran Rocha",
        titulacao: "Doutora em Informática",
        image: 'assets/img/docentes/ana.jpg',
        // bio: "Profª IComp/UFAM. Desenvolveu o ReComP, uma ferramenta inovadora para comunicação de requisitos em projetos. É membro do Grupo de Pesquisa USES e ministra aulas e palestras para formação de novos profissionais na área de desenvolvimento de software",
        bio: "Graduação em Análise de Sistemas pelo Centro de Ensino Superior (FUCAPI - 2007), Mestre em Ciências da Computação pela Universidade Federal de Pernambuco (2010), Doutorado em Informática na Universidade Federal do Amazonas (2020) na linha de pesquisa de Engenharia de Software, pelo Programa de Pós-Graduação em Informática (PPGI). Atualmente é Professora Magisterio Superior na Universidade Federal do Amazonas e membro do Grupo de Pesquisa USES (Usabilidade e Engenharia de Software) e dedicando-se à aplicação e melhoria das práticas de engenharia de software nas empresas. Durante o doutorado, desenvolveu o ReComP (Framework of Requirements Communication based on Perspective), uma ferramenta para avaliar e melhorar as especificações de requisitos utilizados na comunicação de requisitos pelas equipes de projeto. Possui experiência em: análise e levantamento de requisitos, modelagem e especificação de software, aplicações de Design Thinking para criação de soluções inovadoras, avaliação de usabilidade, User eXperience e testes exploratórios. Além disso, possui experiência em ministrar aulas e palestras voltadas para educação de novos membros de equipes de desenvolvimento de software.",
        linkLinkedin: "https://www.linkedin.com/in/ana-oran-95744320/",
        linkGit: ""
    },
    {
        id: "6",
        nome: "André Carvalho",
        image: 'assets/img/docentes/andre.gif',
        titulacao: "Doutor em Informática",
        // bio: "Profº IComp/UFAM. Especialista em Machine Learning, colabora com empresas como a Motorola, e aplica técnicas de Deep Learning em Processamento de Linguagem Natural.",
        bio: "Professor da Universidade Federal do Amazonas (UFAM), é Doutor em Informática pela mesma instituição. Atualmente é o coordenador do curso de Engenharia de Software. Durante sua carreira, coordenou diversos projetos de pesquisa, tanto com financiamento público quanto em parceria com empresas privadas como Samsung e Nokia, normalmente focados na aplicação de técnicas de Aprendizagem de Máquina. Apesar de ter iniciado a carreica pesquisando temas relacionados à Recuperação de Informação (RI), mais recentemente seu trabalho tem tido um maior foco na aplicação de técnicas de aprendizagem profunda para Processamento de Linguagem Natural (PLN).",
        linkLinkedin: "https://www.linkedin.com/in/andr%C3%A9-carvalho-1aa3bb51/",
        linkGit: ""
    },
    {
        id: "7",
        nome: "Arcanjo Miguel M. Lopes",
        image: 'assets/img/docentes/arcanjo.enc',
        titulacao: "Mestre em Informática",
        // bio: "Mestrado pelo IComp/UFAM com aplicação de Inteligência Artificial na Educação. Desenvolvedor FullStack do Sistema Web de Tutoria Inteligente chamado LEIA, voltado para o ensino de matemática",
        bio: "Atualmente cursando Doutorado no Programa de Pós-Graduação em Computação da Universidade Federal do Amazonas (UFAM). Idealizador do Sistema Tutor Inteligente LEIA (https://webitsleia.online/). Possui Mestrado (2019) em Informática pela UFAM com ênfase em Computação Aplicada à Educação. Especialista em Computação Forense pela Faculdade Evolução (2018) e graduação em Ciência da Computação pelo Centro Universitário do Norte UNINORTE (2016). Pesquisador na área de Inteligência Artificial e Sistemas Tutores Inteligentes.",
        linkLinkedin: "https://www.linkedin.com/in/arcanjo-lopes-9299b395/",
        linkGit: "https://github.com/arcanjommlopes"
    },



]

export default DocentesData;